@use "sass:math";
.text-banner {
	background-color: #ffcf8d;
	font-size: 14px;
	line-height: 1.5714;
	color: #000;
	padding: 9px 0;

	p {
		margin: 0;
	}

	a {
		color: inherit;
		text-decoration: underline;

		&:hover {
			color: inherit;
			text-decoration: none;
		}
	}
}