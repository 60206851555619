.text-banner {
	background-color: #ffcf8d;
	font-size: 14px;
	line-height: 1.5714;
	color: #000;
	padding: 9px 0;
}
.text-banner p {
	margin: 0;
}
.text-banner a {
	color: inherit;
	text-decoration: underline;
}
.text-banner a:hover {
	color: inherit;
	text-decoration: none;
}